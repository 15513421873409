import * as ReactDOM from 'react-dom'
import { useLocales } from '@sevenrooms/core/locales'
import { Box, Window } from '@sevenrooms/core/ui-kit/layout'
import { List, ListItem, Text } from '@sevenrooms/core/ui-kit/typography'
import { OverridesModal, ReviewChangesModal } from '@sevenrooms/core/ui-kit/vx-layout'
import { svrExport } from '@sevenrooms/core/utils'
import { AppRoot } from '@sevenrooms/mgr-core'
import { ShiftOverrides, type ShiftOverridesProps } from '@sevenrooms/mgr-shift'
import { ExportsLocales } from './Exports.locales'

function ReviewOverrides({
  changeLogs,
  shiftOverrides,
  onClose,
}: {
  changeLogs: string[]
  shiftOverrides: ShiftOverridesProps['overrides']
  onClose: () => void
}) {
  const { formatMessage } = useLocales()
  return (
    <Window active>
      <OverridesModal onClose={onClose} isShift>
        <Box backgroundColor="secondaryBackground" borderRadius="s" p="m" mt="m" borderColor="borders">
          {Array.isArray(changeLogs) && changeLogs.length > 0 ? (
            <>
              <Text>{formatMessage(ExportsLocales.reviewOverrideHeaderText)}</Text>
              <Box mt="m">
                <List spacing="s">
                  {changeLogs.map((log, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ListItem key={index}>
                      <Text>{log}</Text>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </>
          ) : (
            <Text>{formatMessage(ExportsLocales.noChanges)}</Text>
          )}
        </Box>
        <ShiftOverrides overrides={shiftOverrides} />
      </OverridesModal>
    </Window>
  )
}

const renderOverridesModal = (
  elem: HTMLElement,
  { changeLogs, shiftOverrides, onClose }: { changeLogs: string[]; shiftOverrides: ShiftOverridesProps['overrides']; onClose: () => void }
) => {
  const closeModal = () => elem && ReactDOM.unmountComponentAtNode(elem)
  ReactDOM.render(
    <AppRoot>
      <ReviewOverrides
        changeLogs={changeLogs}
        shiftOverrides={shiftOverrides}
        onClose={() => {
          closeModal()
          onClose()
        }}
      />
    </AppRoot>,
    elem
  )
}

svrExport('MgrOverridesModal', 'render', renderOverridesModal)

function ReviewChanges({
  name,
  changeLogs,
  onCancel,
  onDiscard,
  onSave,
}: {
  name: string
  changeLogs: string[]
  onCancel: () => void
  onDiscard: () => void
  onSave: () => void
}) {
  const { formatMessage } = useLocales()
  return (
    <Window active>
      <ReviewChangesModal name={name ?? ''} onCancel={onCancel} onDiscard={onDiscard} onSave={onSave}>
        <Box backgroundColor="secondaryBackground" borderRadius="s" p="m" mt="m">
          {Array.isArray(changeLogs) && changeLogs.length > 0 ? (
            <>
              <Text>{formatMessage(ExportsLocales.reviewChangesHeaderText)}</Text>
              <Box mt="m">
                <List spacing="s">
                  {changeLogs.map((log, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ListItem key={index}>
                      <Text wordBreak="break-word">{log}</Text>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </>
          ) : (
            <Text>{formatMessage(ExportsLocales.noChanges)}</Text>
          )}
        </Box>
      </ReviewChangesModal>
    </Window>
  )
}

const renderReviewChangesModal = (
  elem: HTMLElement,
  {
    changeLogs,
    onDiscard,
    onSave,
    name,
  }: {
    changeLogs: string[]
    onDiscard: () => void
    onSave: () => void
    name?: string
  }
) => {
  const closeModal = () => elem && ReactDOM.unmountComponentAtNode(elem)
  ReactDOM.render(
    <AppRoot>
      <ReviewChanges
        name={name ?? ''}
        changeLogs={changeLogs}
        onCancel={() => {
          closeModal()
        }}
        onDiscard={() => {
          closeModal()
          onDiscard()
        }}
        onSave={() => {
          closeModal()
          onSave()
        }}
      />
    </AppRoot>,
    elem
  )
}

svrExport('ReviewChangesModal', 'render', renderReviewChangesModal)
